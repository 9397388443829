import React from 'react'
import axios from 'axios'
import { FaClipboardList } from 'react-icons/fa'
import { FaUserCog } from 'react-icons/fa'

import OrderList from './OrderList'
import '../css/CompanyList.css'

const CompanyList = ({ user, onShowUserManagement }) => {
  const [companies, setCompanies] = React.useState([])
  const [selectedCompanyId, setSelectedCompanyId] = React.useState(null)
  const [selectedCompanyName, setSelectedCompanyName] = React.useState('')

  React.useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies`
        )

        setCompanies(response.data)
      } catch (error) {
        console.error('Error fetching companies:', error)
      }
    }

    fetchCompanies()
  }, [])

  const handleCompanyClick = (companyId, companyName) => {
    setSelectedCompanyId(companyId)
    setSelectedCompanyName(companyName)
  }

  const handleBackToCompanies = () => {
    setSelectedCompanyId(null)
    setSelectedCompanyName('')
  }

  const handleShowMyOrders = () => {
    setSelectedCompanyId('myOrders')
    setSelectedCompanyName('Moje zamówienia')
  }

  return (
    <div className='company-list-container'>
      {user.role === 'admin' && (
        <button className='user-settings' onClick={onShowUserManagement}>
          <FaUserCog />
        </button>
      )}
      {selectedCompanyId ? (
        <div className='company-orders'>
          <button className='back-btn' onClick={handleBackToCompanies}>
            <FaClipboardList />
          </button>

          <OrderList
            companyId={selectedCompanyId}
            companyName={selectedCompanyName}
            userName={user.name}
            userRole={user.role}
          />
        </div>
      ) : (
        <div className='company-list'>
          <h2>Wybierz firmę</h2>
          <div className='companies-wrapper'>
            <div onClick={handleShowMyOrders} className='company-item'>
              Pokaż moje zamówienia
              <span className='tooltip'>
                Kliknij, aby zobaczyć swoje zamówienia
              </span>
            </div>
            {companies.map((company) => (
              <div
                key={company.id}
                onClick={() =>
                  handleCompanyClick(company.id, company.companyname)
                }
                className='company-item'
              >
                {company.companyname}
                <span className='tooltip'>Kliknij, aby wybrać firmę</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CompanyList
