import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AddOrderForm from './AddOrderForm'
import EditOrderForm from './EditOrderForm'
import ConfirmationDialog from './ConfirmationDialog'

import { BsTrash } from 'react-icons/bs'

import { TiEdit } from 'react-icons/ti'
import { IoMdCloseCircle } from 'react-icons/io'
import { FaRegFileArchive } from 'react-icons/fa'
import { IoMdAddCircle } from 'react-icons/io'
import { MdRestorePage } from 'react-icons/md'

import '../css/order.css'

const OrderList = ({ companyId, companyName, userName, userRole }) => {
  const [orders, setOrders] = useState([])
  const [originalOrders, setOriginalOrders] = useState([])
  const [employees, setEmployees] = useState([])
  const [users, setUsers] = useState([])
  const [quarters, setQuarters] = useState([])
  const [storagePlaces, setStoragePlaces] = useState([])
  // Nowy stan dla przycisku archiwum
  const [showArchived, setShowArchived] = useState(false)
  const [confirmArchive, setConfirmArchive] = useState(false)
  const [archiveMessage, setArchiveMessage] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [archiveOrderId, setArchiveOrderId] = useState(null)
  const [deleteOrderId, setDeleteOrderId] = useState(null)
  const [regions, setRegions] = useState([])

  // Nowa metoda do obsługi przycisku archiwum
  const handleArchiveToggle = () => {
    setShowArchived((prevState) => !prevState)
  }

  const [sortCriteria, setSortCriteria] = useState({
    field: 'id',
    order: 'desc',
  })
  const [filterCriteria, setFilterCriteria] = useState({
    status: '',
    employee: '',
    quarter: '',
    storagePlace: '',
  })

  const resetButtons = () => {
    setIsAdding(false)
  }

  const handleCancelAddOrder = () => {
    setIsAdding(false)
  }

  const [alertMessage, setAlertMessage] = useState('')
  const [isEditing, setIsEditing] = useState(null)
  const [newOrder, setNewOrder] = useState({
    ile: '',
    maszyna: '',
    model: '',
    wersja: '',
    wyposazenie: '',
    nr_fabryczny: '',
    nr_zam: '',
    klient: '',
    czasrealizacji: '',
    miejsce_skl: '',
    kwartal: '',
    name: '',
    status: '',
  })
  const [isAdding, setIsAdding] = useState(false)

  const fetchOrders = async () => {
    try {
      const ordersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders`
      )
      const uniqueQuarters = [
        ...new Set(ordersResponse.data.map((order) => order.kwartal)),
      ]
      const uniqueNames = [
        ...new Set(ordersResponse.data.map((order) => order.name)),
      ]
      const uniqueStoragePlaces = [
        ...new Set(ordersResponse.data.map((order) => order.miejsce_skl)),
      ]

      setOrders(ordersResponse.data)
      setOriginalOrders(ordersResponse.data)
      setQuarters(uniqueQuarters)
      setEmployees(uniqueNames)
      setStoragePlaces(uniqueStoragePlaces)
    } catch (error) {
      console.error('Error fetching orders:', error)
    }
  }
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`)
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }
  const fetchRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/regions`
      )
      setRegions(response.data)
    } catch (error) {
      console.error('Error fetching regions:', error)
    }
  }

  useEffect(() => {
    fetchOrders()
    fetchUsers()
    fetchRegions()
  }, [showArchived, filterCriteria])

  useEffect(() => {
    let filteredOrders = [...originalOrders]

    if (userRole === 'admin' && companyId === 'myOrders') {
      setOrders(filteredOrders)
    } else if (companyId === 'myOrders' && userName !== null) {
      filteredOrders = originalOrders.filter((order) => order.name === userName)
      setOrders(filteredOrders)
    } else if (companyId) {
      filteredOrders = originalOrders.filter(
        (order) => order.company === companyId
      )
      setOrders(filteredOrders)
    } else {
      setOrders(filteredOrders)
    }

    // Dodaj filtrowanie po regionach
    if (userRole !== 'admin' && userName !== null && users.length > 0) {
      const currentUser = users.find((user) => user.name === userName)
      if (currentUser) {
        const userRegionId = currentUser.region
        filteredOrders = filteredOrders.filter(
          (order) => order.region === userRegionId
        )
        setOrders(filteredOrders)
      } else {
        console.error('Current user not found in users list')
      }
    }
  }, [companyId, originalOrders, userName, userRole, users])

  const handleCancelEdit = () => {
    setIsEditing(null)
  }

  const handleAddOrderSuccess = (newOrder) => {
    setOrders([...orders, newOrder])
    setOriginalOrders([...originalOrders, newOrder])
    setIsAdding(false)
  }

  const applyFilters = () => {
    let filteredOrders = [...orders]

    if (filterCriteria.status) {
      filteredOrders = filteredOrders.filter(
        (order) => order.status === filterCriteria.status
      )
    }

    if (filterCriteria.employee) {
      filteredOrders = filteredOrders.filter(
        (order) => order.name === filterCriteria.employee
      )
    }

    if (filterCriteria.quarter) {
      filteredOrders = filteredOrders.filter(
        (order) => order.kwartal === filterCriteria.quarter
      )
    }

    if (filterCriteria.storagePlace) {
      filteredOrders = filteredOrders.filter(
        (order) => order.miejsce_skl === filterCriteria.storagePlace
      )
    }

    return filteredOrders
  }

  const handleSortChange = (field) => {
    const newOrder =
      sortCriteria.field === field && sortCriteria.order === 'asc'
        ? 'desc'
        : 'asc'
    setSortCriteria({ field, order: newOrder })
  }

  const handleFilterChange = (event) => {
    const { name, value } = event.target
    setFilterCriteria({ ...filterCriteria, [name]: value })
  }

  const handleResetFilters = () => {
    setFilterCriteria({
      status: '',
      employee: '',
      quarter: '',
      storagePlace: '',
    })
  }

  const handleArchive = (id, archive) => {
    setArchiveOrderId(id)
    setConfirmArchive(true)
    setArchiveMessage(
      archive
        ? 'Czy na pewno chcesz zarchiwizować to zamówienie?'
        : 'Czy chcesz przywrócić zamówienie?'
    )
  }
  const handleDelete = (id) => {
    setDeleteOrderId(id)
    setConfirmDelete(true)
  }

  const confirmArchiveOrder = async () => {
    const id = archiveOrderId
    setConfirmArchive(false)

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/orders/archive/${id}`)
      const updatedOrders = orders.map((order) =>
        order.id === id ? { ...order, archived: true } : order
      )
      setOrders(updatedOrders)
      setOriginalOrders(updatedOrders)
      setAlertMessage('Zamówienie zostało zarchiwizowane.')
      fetchOrders()
    } catch (error) {
      console.error('Error archiving order:', error)
      setAlertMessage('Błąd podczas archiwizowania zamówienia.')
    }
  }

  const confirmDeleteOrder = async () => {
    const id = deleteOrderId
    setConfirmDelete(false)

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/orders/${id}`)
      setOrders(orders.filter((order) => order.id !== id))
      setOriginalOrders(originalOrders.filter((order) => order.id !== id))
      setAlertMessage('Zamówienie zostało usunięte.')
      fetchOrders()
    } catch (error) {
      console.error('Error deleting order:', error)
    }
  }
  const handleEdit = (id) => {
    setIsEditing((prevId) => (prevId === id ? null : id)) // Toggle editing based on current state
  }

  const handleSaveEdit = async (id) => {
    try {
      const editedOrder = orders.find((order) => order.id === id)
      await axios.put(
        `${process.env.REACT_APP_API_URL}/orders/${id}`,
        editedOrder
      )
      setIsEditing(null)
    } catch (error) {
      console.error('Error saving order:', error)
    }
  }
  const compareValues = (a, b) => {
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b)
    } else if (typeof a === 'number' && typeof b === 'number') {
      return a - b
    } else {
      return 0 // Default case or handle other types as needed
    }
  }
  const handleChangeEdit = (id, field, value) => {
    setOrders(
      orders.map((order) =>
        order.id === id ? { ...order, [field]: value } : order
      )
    )
  }
  const sortedOrders = applyFilters().sort((a, b) => {
    const isAsc = sortCriteria.order === 'asc' ? 1 : -1
    switch (sortCriteria.field) {
      case 'id':
        return isAsc * (a.id - b.id)
      case 'miejsce_skl':
      case 'kwartal':
      case 'name':
      case 'ile':
      case 'maszyna':
      case 'model':
      case 'wersja':
      case 'wyposazenie':
      case 'nr_fabryczny':
      case 'nr_zam':
      case 'klient':
      case 'czasrealizacji':
        return (
          isAsc * compareValues(a[sortCriteria.field], b[sortCriteria.field])
        )
      default:
        return 0
    }
  })

  return (
    <div className='order-list-container'>
      <ConfirmationDialog
        isOpen={confirmArchive}
        message={archiveMessage}
        onCancel={() => setConfirmArchive(false)}
        onConfirm={confirmArchiveOrder}
      />

      <ConfirmationDialog
        isOpen={confirmDelete}
        message='Czy na pewno chcesz usunąć to zamówienie?'
        onCancel={() => setConfirmDelete(false)}
        onConfirm={confirmDeleteOrder}
      />

      <h3>{companyName}</h3>
      <div className='header'>
        <div className='filters'>
          {userRole === 'admin' && (
            <div className=''>
              <button
                className={`back-btn ${isAdding ? 'active' : 'inactive'}`}
                onClick={() => {
                  resetButtons()
                  setIsAdding(!isAdding)
                }}
              >
                {isAdding ? <IoMdCloseCircle /> : <IoMdAddCircle />}
              </button>
            </div>
          )}
          <div className='filter-group'>
            <label>Status zamówienia:</label>
            <select
              name='status'
              value={filterCriteria.status}
              onChange={handleFilterChange}
            >
              <option value=''>Wszystkie</option>
              <option disabled>--------------------</option>
              <option value='white'>Zamówiony</option>
              <option value='blue'>WZ</option>
              <option value='yellow'>Wymaga uwagi</option>
              <option value='green'>Czeka na placu</option>
            </select>
          </div>
          <div className='filter-group'>
            <label>Kwartał:</label>
            <select
              name='quarter'
              value={filterCriteria.quarter}
              onChange={handleFilterChange}
            >
              <option value=''>Wybierz kwartał</option>
              <option disabled>--------------------</option>
              {quarters.map((quarter) => (
                <option key={quarter} value={quarter}>
                  {quarter}
                </option>
              ))}
            </select>
          </div>
          <div className='filter-group'>
            <label>Pracownik:</label>
            <select
              name='employee'
              value={filterCriteria.employee}
              onChange={handleFilterChange}
              disabled={employees.length === 0}
            >
              <option value=''>Wszyscy</option>
              <option disabled>--------------------</option>
              {users
                .filter((user) => !user.archived) // Filtrujemy zarchiwizowanych użytkowników
                .map((user) => (
                  <option key={user.name} value={user.name}>
                    {user.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='filter-group'>
            <label>Miejsce składowania:</label>
            <select
              name='storagePlace'
              value={filterCriteria.storagePlace}
              onChange={handleFilterChange}
            >
              <option value=''>Wybierz miejsce</option>
              <option disabled>--------------------</option>
              {storagePlaces.map((place) => (
                <option key={place} value={place}>
                  {place}
                </option>
              ))}
            </select>
          </div>
          <button className='reset-btn' onClick={handleResetFilters}>
            Resetuj filtry
          </button>
          {userRole === 'admin' && (
            <button
              className={`toggle-add-btn ${
                showArchived ? 'active' : 'inactive'
              }`}
              onClick={handleArchiveToggle}
            >
              {showArchived ? 'Wróć' : 'Archwium'}
            </button>
          )}
        </div>
      </div>
      {isAdding ? (
        <AddOrderForm
          onAddOrderSuccess={handleAddOrderSuccess}
          employees={employees}
          storagePlaces={storagePlaces}
          quarters={quarters}
          fetchOrders={fetchOrders}
          handleCancelAddOrder={handleCancelAddOrder} // Przekazujemy funkcję anulowania dodawania
        />
      ) : null}
      {sortedOrders.filter((order) => !order.archived).length > 0 ? (
        <table className='order-table'>
          <thead>
            <tr>
              <th onClick={() => handleSortChange('ile')}>
                Ile
                {sortCriteria.field === 'ile' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('maszyna')}>
                Maszyna
                {sortCriteria.field === 'maszyna' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('model')}>
                Model
                {sortCriteria.field === 'model' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('wersja')}>
                Wersja
                {sortCriteria.field === 'wersja' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('wyposazenie')}>
                Wyposażenie
                {sortCriteria.field === 'wyposazenie' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('nr_fabryczny')}>
                Nr fabryczny
                {sortCriteria.field === 'nr_fabryczny' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('nr_zam')}>
                Nr zamówienia
                {sortCriteria.field === 'nr_zam' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('klient')}>
                Klient
                {sortCriteria.field === 'klient' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('czasrealizacji')}>
                Czas realizacji
                {sortCriteria.field === 'czasrealizacji' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('miejsce_skl')}>
                Miejsce składowania
                {sortCriteria.field === 'miejsce_skl' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('kwartal')}>
                Kwartal
                {sortCriteria.field === 'kwartal' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              <th onClick={() => handleSortChange('name')}>
                Pracownik
                {sortCriteria.field === 'name' && (
                  <span>{sortCriteria.order === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </th>
              {userRole === 'admin' && <th>Operacje</th>}
            </tr>
          </thead>
          <tbody>
            {sortedOrders
              .filter((order) =>
                showArchived ? order.archived : !order.archived
              ) // Filtrujemy zarchiwizowane zamówienia
              .map((order) => {
                const user = users.find((user) => user.name === order.name)
                const userIsArchived = user ? user.archived : false
                return (
                  <tr
                    key={order.id}
                    className={`${order.status} ${
                      order.archived || userIsArchived ? 'archived' : ''
                    }`}
                  >
                    {isEditing === order.id ? (
                      <td colSpan={userRole === 'admin' ? 13 : 12}>
                        <EditOrderForm
                          order={order}
                          onEditOrderSuccess={handleSaveEdit}
                          fetchOrders={fetchOrders}
                          handleCancelEdit={handleCancelEdit}
                        />
                      </td>
                    ) : (
                      <>
                        <td
                          style={{
                            width: '16px',
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.ile !== 0 ? order.ile : ''}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.maszyna}
                        </td>
                        <td
                          style={{
                            width: '170px',
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.model}
                        </td>
                        <td
                          style={{
                            width: '1px',
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.wersja}
                        </td>
                        <td
                          style={{
                            width: '251px',
                            textAlign: 'left',
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.wyposazenie.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.nr_fabryczny}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.nr_zam}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.klient}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.czasrealizacji !== '0000-00-00'
                            ? order.czasrealizacji
                            : ''}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.miejsce_skl}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.kwartal}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              order.archived || userIsArchived
                                ? 'lightgrey'
                                : 'inherit',
                          }}
                        >
                          {order.name}
                        </td>
                        {userRole === 'admin' && (
                          <td
                            style={{
                              backgroundColor:
                                order.archived || userIsArchived
                                  ? 'lightgrey'
                                  : 'inherit',
                            }}
                          >
                            <>
                              {!order.archived ? (
                                <>
                                  <button
                                    className='edit-btn'
                                    onClick={() => handleEdit(order.id)}
                                  >
                                    <TiEdit />
                                  </button>
                                  <button
                                    className='archive-btn'
                                    onClick={() =>
                                      handleArchive(order.id, true)
                                    }
                                  >
                                    <FaRegFileArchive />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className='restore-btn'
                                    onClick={() =>
                                      handleArchive(order.id, false)
                                    }
                                  >
                                    <MdRestorePage />
                                  </button>
                                  <button
                                    className='delete-btn'
                                    onClick={() => handleDelete(order.id)}
                                  >
                                    <BsTrash />
                                  </button>
                                </>
                              )}
                            </>
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
      ) : (
        <div className='no-results'>
          Brak wyników spełniających kryteria wyszukiwania.
        </div>
      )}
    </div>
  )
}

export default OrderList
