import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../css/AddOrderForm.css'

const AddOrderForm = ({
  onAddOrderSuccess,
  employees,
  storagePlaces,
  quarters,
  fetchOrders,
  handleCancelAddOrder,
}) => {
  const [newOrder, setNewOrder] = useState({
    ile: '',
    maszyna: '',
    model: '',
    wersja: '',
    wyposazenie: '',
    nr_fabryczny: '',
    nr_zam: '',
    klient: '',
    czasrealizacji: '',
    miejsce_skl: '',
    kwartal: '',
    name: '',
    status: '',
    company: '',
    region: '',
  })

  const [users, setUsers] = useState([])
  const [companies, setCompanies] = useState([])
  const [regions, setRegions] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    // Fetch users, companies, and regions from server
    fetchUsers()
    fetchCompanies()
    fetchRegions()
  }, [])

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`)
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies`
      )
      setCompanies(response.data)
    } catch (error) {
      console.error('Error fetching companies:', error)
    }
  }

  const fetchRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/regions`
      )
      setRegions(response.data)
    } catch (error) {
      console.error('Error fetching regions:', error)
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setNewOrder({ ...newOrder, [name]: value })
  }

  const validateForm = () => {
    if (
      !newOrder.maszyna ||
      !newOrder.model ||
      !newOrder.klient ||
      !newOrder.name ||
      !newOrder.status ||
      !newOrder.company
    ) {
      setError(
        'Proszę wypełnić wymagane pola: Maszyna, Model, Klient, Pracownik, Status, Firma.'
      )
      return false
    }
    return true
  }

  const handleSaveNewOrder = async () => {
    setError('')

    if (!validateForm()) {
      return
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders`,
        newOrder
      )

      onAddOrderSuccess(response.data) // Notify parent component about the new order
      setNewOrder({
        ile: '',
        maszyna: '',
        model: '',
        wersja: '',
        wyposazenie: '',
        nr_fabryczny: '',
        nr_zam: '',
        klient: '',
        czasrealizacji: '',
        miejsce_skl: '',
        kwartal: '',
        name: '',
        status: '',
        company: '',
        region: '',
      })

      // Display success alert
      window.alert('Udało się dodać nowe zamówienie!')
      fetchOrders()
      // Clear error message after 3 seconds
      setTimeout(() => {
        setError('')
      }, 3000)
    } catch (error) {
      console.error('Error adding new order:', error)
    }
  }

  return (
    <div className='new-order-form'>
      <h4>Dodaj nowe zamówienie</h4>{' '}
      {error && <div className='new-order-error'>{error}</div>}
      <input
        type='number'
        placeholder='Ile'
        name='ile'
        value={newOrder.ile}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Maszyna'
        name='maszyna'
        value={newOrder.maszyna}
        onChange={handleInputChange}
        required
      />
      <input
        type='text'
        placeholder='Model'
        name='model'
        value={newOrder.model}
        onChange={handleInputChange}
        required
      />
      <input
        type='text'
        placeholder='Wersja'
        name='wersja'
        value={newOrder.wersja}
        onChange={handleInputChange}
      />
      <textarea
        placeholder='Wyposażenie'
        name='wyposazenie'
        value={newOrder.wyposazenie}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Nr fabryczny'
        name='nr_fabryczny'
        value={newOrder.nr_fabryczny}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Nr zamówienia'
        name='nr_zam'
        value={newOrder.nr_zam}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Klient'
        name='klient'
        value={newOrder.klient}
        onChange={handleInputChange}
        required
      />
      <input
        type='text'
        placeholder='Czas realizacji'
        name='czasrealizacji'
        value={newOrder.czasrealizacji}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Miejsce składowania'
        name='miejsce_skl'
        value={newOrder.miejsce_skl}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Kwartał'
        name='kwartal'
        value={newOrder.kwartal}
        onChange={handleInputChange}
      />
      <select
        name='name'
        value={newOrder.name}
        onChange={handleInputChange}
        required
      >
        <option value=''>Wybierz pracownika</option>
        {users.map((user) => (
          <option key={user.id} value={user.id}>
            {user.name}
          </option>
        ))}
      </select>
      <select
        name='status'
        value={newOrder.status}
        onChange={handleInputChange}
        required
      >
        <option value=''>Wybierz status</option>
        <option value='white'>Zamówiony</option>
        <option value='blue'>WZ</option>
        <option value='yellow'>Wymaga uwagi</option>
        <option value='green'>Czeka na placu</option>
      </select>
      <select
        name='company'
        value={newOrder.company}
        onChange={handleInputChange}
        required
      >
        <option value=''>Wybierz firmę</option>
        {companies.map((company) => (
          <option key={company.id} value={company.id}>
            {company.companyname}
          </option>
        ))}
      </select>
      <select
        name='region'
        value={newOrder.region}
        onChange={handleInputChange}
      >
        <option value=''>Wybierz region</option>
        {regions.map((region) => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </select>
      <div className=''></div>
      <div className='buttons'>
        {' '}
        <button className='cancel-btn' onClick={handleCancelAddOrder}>
          Anuluj
        </button>
        <button onClick={handleSaveNewOrder}>Zapisz</button>
      </div>
    </div>
  )
}

export default AddOrderForm
